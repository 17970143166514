import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="d27f7897696b54a9444f46a1c911fa51ff25132b"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/infomateriaal-diaree/">Infomateriaal</a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="gallery flexslider">
          <ul className="slides">
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_04.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>STRESS EN DIARREE</h2>
                    <p>
                      Stresserende en belastende situaties kennen we allemaal,
                      zowel op het werk als privé. Wat velen echter niet weten,
                      is dat stress en zenuwachtigheid diarree kunnen
                      veroorzaken. Hier kom je meer te weten over de oorzaken
                      van <strong>stress en diarree</strong> alsook over wat je
                      ertegen kunt doen.
                    </p>
                    <a
                      href="/feiten-over-diarree/stress-en-diarree/"
                      className="button radius"
                    >
                      Stress en diarree
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_03.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>TIPS BIJ DIARREE</h2>
                    <p>
                      Rond het behandelen van de{" "}
                      <strong>symptomen van diarree</strong> bestaan er nog vele
                      misverstanden en mythes. Hier vind je enkele tips over het
                      behandelen van de symptomen van diarree.
                    </p>
                    <a
                      href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/"
                      className="button radius"
                    >
                      Tips bij diarree
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_02.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>HOE WERKT IMODIUM®</h2>
                    <p>
                      Volgend filmpje toont je de werking van{" "}
                      <strong>IMODIUM®</strong>: hoe <strong>diarree</strong>{" "}
                      belangrijke vloeistoffen en elektrolyten aan je lichaam
                      onttrekt en hoe <strong>IMODIUM®</strong> hulp biedt bij diarree.
                    </p>
                    <a
                      href="/hoe-werkt-imodium-instant/"
                      className="button radius"
                    >
                      Hoe werkt IMODIUM®
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_01.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>DIARREE OP REIS</h2>
                    <p>
                      De vakantietijd is voor de meeste mensen de mooiste tijd
                      van het jaar. Niemand wil deze kostbare dagen dan ook met{" "}
                      <strong>diarree</strong> op de hotelkamer doorbrengen.
                      Daarom vind je hier enkele nuttig tips en wat meer
                      informatie over het voorkomen en behandelen van de
                      symptomen van&nbsp;<strong>diarree op reis</strong>.
                    </p>
                    <a href="/diarree-op-reis/" className="button radius">
                      Dairree op reis
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="row gallery-nav">
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>STRESS EN DIARREE
            </a>
          </div>
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>TIPS BIJ DIARREE
            </a>
          </div>
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>HOE WERKT IMODIUM®
            </a>
          </div>
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>DIARREE OP REIS
            </a>
          </div>
          <div className="three columns indicator" />
        </div>
        <div className="main row">
          <div className="six columns">
            <div className="spotlight row">
              <div className="six columns">
                <h2>
                  <a href="#" className="playVideo" id>
                    IMODIUM<sup>®</sup> SPOT
                  </a>
                  {/* <a href="#" id>
                    IMODIUM<sup>®</sup> SPOT
                  </a> */}
                </h2>
                <p>
                  Bekijk de IMODIUM<sup>®</sup>
                  <br />
                 SPOT
                </p>
              </div>
              <div className="six columns">
                <div className="img">
                  {/* <a className="playVideo" id href="#">
                    <img
                      src="/assets/img/spotlight.jpg"
                      width="248"
                      height="139"
                    />
                  </a> */}
                  <a id href="#">
                    <img
                      src="/assets/img/KeyVisual_Traffic Jam_NL.jpg"
                      width="248"
                      height="139"
                    />
                  </a>
                </div>
                  <a href="#" className="badge highlight playVideo" id>
                  <span>
                    Bekijk
                    <br />
                    de spot
                  </span>
                  <span className="arrow">&gt;</span>
                </a>
              </div>
            </div>
            <div className="spotlight row">
              <div className="six columns">
                <h2>
                  <a href="/diarree-op-reis/reizigersdiaree-vermijden/">
                    DIARREE OP REIS
                  </a>
                </h2>
                <p>
                  Lees deze handige tips om diarree op vakantie te vermijden
                </p>
              </div>
              <div className="six columns">
                <div className="img">
                  <a href="/diarree-op-reis/reizigersdiaree-vermijden/">
                    <img
                      src="/assets/img/home-widget-reisfolder.jpg"
                      width="248"
                      height="139"
                    />
                  </a>
                </div>
                <a
                  href="/diarree-op-reis/reizigersdiaree-vermijden/"
                  className="badge highlight"
                >
                  <span>
                    Verder
                    <br />
                    lezen
                  </span>
                  <span className="arrow">&gt;</span>
                </a>
              </div>
            </div>
            <div className="spotlight row">
              <div className="six columns">
                <h2>
                  <a href="/veelgestelde-vragen-diarree/">
                    VEELGESTELDE VRAGEN
                  </a>
                </h2>
              </div>
              <div className="six columns"
              style={{
                "padding-left": "39px"
              }}>
                <p>
                  Hier vind je veelgestelde vragen over diarree en IMODIUM®.
                </p>
                <a href="/veelgestelde-vragen-diarree/">Verder lezen &gt;</a>
              </div>
            </div>
          </div>
          </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>
                        <li><a href="/infomateriaal-diaree/">Infomateriaal</a></li>
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Antwerpseweg 15-17, B-2340 Beerse. Dossiernr: BE-IM-2000027.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/IMODIUM TRAFFIC JAM+TAG THUISAPOTHEEK_VL15_181220.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
